import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import SettingsModal from "./SettingsModal";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            showSettings: false,
            showCompass: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    resetSettingsVisible = () => {
        this.setState({ showSettings: false });
    }
    toggleSettings = () => {
        this.setState({ showSettings: !this.state.showSettings });
    }
    openExternalURL() {
        window.open("https://www.metoffice.gov.uk/weather/specialist-forecasts/mountain/brecon-beacons")
    }

    toggleCompass = () => {
        this.setState({ showCompass: !this.state.showCompass });
    }
    hideCompass() {
        this.setState({ showCompass: false });
    }
    componentDidMount() {
        if (localStorage.getItem("firstname") === null) {
            this.setState({ showSettings: true });
        }
    }

    render() {
        return (
            <header>
                <SettingsModal visible={this.state.showSettings} onClose={this.resetSettingsVisible} />
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-blue border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">WildfireApp</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {/*<NavItem>*/}
                            {/*    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                            {/*</NavItem>*/}
                            <NavItem>
                                <NavLink tag={Link} onClick={this.toggleSettings} >Settings</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} onClick={this.openExternalURL} >Met Office Forecast</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
