
const isDevMode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false;
const apiUrl = isDevMode ? "https://localhost:7053/" : "https://wildfire.beacons-npa.gov.uk/";
const osapikey = "VUqinBBHsGPgqoeGHsKwVS429iAfFhGR";

// 51.8700, -3.4500
const mapcenter = {
    lat: 51.87,
    lng: -3.45,
};
const gisserver = isDevMode ? "http://172.16.6.180:8080/" : "https://gis.beacons-npa.gov.uk/";
const maplayers = [
    {
        name: "Deep Peat",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:deep_peat&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "black",
            color: "black"
        }
    },
    {
        name: "Fire Load",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:fire_load&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "orange",
            color: "orange"
        }
    },
    {
        name: "Land Ownership",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:landownership&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "brown",
            color: "brown"
        }
    },
    {
        name: "Linear Firebreaks",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:linear_firebreaks&outputFormat=json&srsName=EPSG:4326",
        style: {
            weight: 7
        }
    },
    {
        name: "Polygon Firebreaks",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:polygon_firebreaks&outputFormat=json&srsName=EPSG:4326",
        style: {}
    },
    {
        name: "Off Road Access Routes",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:off_road_access_routs&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "red",
            color: "red",
            weight: 6
        }
    },
    {
        name: "Forest Roads",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:forest_roads&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "#757575",
            color: "#757575",
            weight: 6
        }
    },
    {
        name: "Protected Areas",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:protected_areas&outputFormat=json&srsName=EPSG:4326",
        style: {
            fillColor: "red",
            color: "red"
        }
    },
    //{
    //    name: "Roads",
    //    url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:roads&outputFormat=json&srsName=EPSG:4326",
    //    style: {}
    //},
    {
        name: "Water Sources",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app:water_source&outputFormat=json&srsName=EPSG:4326",
        style: {}
    },
    {
        name: "Warden Areas",
        url: gisserver + "geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app%3Awardens_areas&maxFeatures=5000&outputFormat=application%2Fjson&srsName=EPSG:4326",
        style: {
            fillColor: "darkgreen",
            color: "darkgreen"
        }
    },
    /* invalid feature names
    wardenAreas: "https://gis.beacons-npa.gov.uk/geoserver/wildfire_field_response_app/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=wildfire_field_response_app%3Awardens_areas&maxFeatures=5000&outputFormat=application%2Fjson&srsName=EPSG:4326",
    
    */
]
const appconfig = {
    isdevmode: isDevMode,
    apiurl: apiUrl,
    osapikey: osapikey,
    center: mapcenter,
    maplayers: maplayers,
};

export default appconfig;