//import React, { Component } from 'react';
//import WildfireMap from './WildfireMap';
//export class Home extends Component {
//  static displayName = Home.name;

//  render() {
//    return (
//        <>
//            <div>sifhiusdhfiushdufhis</div>
//            <WildfireMap />

//        </>

//    );
//  }
//}

import React, { useEffect, useState } from 'react';
import WildfireMap from './WildfireMap';
import SettingsModal from './SettingsModal';
import Connector from "../Connector";
import { useGeolocated } from "react-geolocated";
import { useWakeLock } from 'react-screen-wake-lock';

function Home(props) {
    const { newMessage, events } = Connector();
    const [message, setMessage] = useState("");
    const [seconds, setSeconds] = useState(0);
    const [enabled, setEnabled] = useState(false);
    const [peoplepositions, setPeoplepositions] = useState([]);
    const [position, setPosition] = useState(null);
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
        useGeolocated({
            positionOptions: {
                //enableHighAccuracy: true,
                enableHighAccuracy: false,
                maximumAge: 10,
            },
            userDecisionTimeout: 5000,
            //onSuccess: updateLocation(coords),
            watchPosition: true,
        });
    //useEffect(() => {
    //    if (isGeolocationAvailable && coords) {
    //        console.log(coords.latitude, coords.longitude);
    //    }
    //},[coords])

    const { isSupported, released, request, release } = useWakeLock({
        onRequest: () => { },
        onError: () => { },
        onRelease: () => { },
    });

    useEffect(() => {
        events((_, message) => setMessage(message));
        if (isSupported) {
            request();
        }
        console.log("events effect");
        const timer = setInterval(function () {
            //getPosition();

            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 5000);
    }, []);

    useEffect(() => {
        if (localStorage.getItem("initials") !== null && isGeolocationAvailable && coords) {
            let pos = { firstname: localStorage.getItem("firstname"), lastname: localStorage.getItem("lastname"), initials: localStorage.getItem("initials"), lat: coords.latitude, lon: coords.longitude };
            newMessage(JSON.stringify(pos));
            setPosition({ lng: coords.longitude, lat: coords.latitude });
        }
    }, [seconds])

    useEffect(() => {
        if (message === "") {
            //console.log("ignore");
        } else {
            let msg = {};
            try {
                msg = JSON.parse(message);
                
                let newpostitions = [];
                let added = false;
                for (var o of peoplepositions) {
                    if (o.firstname === msg.firstname && o.lastname === msg.lastname) {
                        newpostitions.push(msg);
                        added = true;
                    } else {
                        newpostitions.push(o);
                    }
                }
                if (!added) {
                    newpostitions.push(msg);
                }
                setPeoplepositions(newpostitions);
            } catch (error) {
                //console.log(error);
            }
            //console.log("pos", JSON.parse( message));
        }

    }, [message])
    //useEffect(() => {
    //    // Exit early if countdown is finished

    //}, [seconds]);
    return (
        <>
            <SettingsModal />
            <WildfireMap peoplepositions={peoplepositions} position={position} />

        </>

    );
}

export default Home;