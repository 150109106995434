import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';

function SettingsModal(props) {
    const [modal, setModal] = useState(false);

    const [firstname, setFirstname] = useState(localStorage.getItem("firstname") === null ? "" : localStorage.getItem("firstname"));
    const [lastname, setLastname] = useState(localStorage.getItem("lastname") === null ? "" : localStorage.getItem("lastname"));
    const [initials, setInitials] = useState(localStorage.getItem("initials") === null ? "" : localStorage.getItem("initials"));

    const toggle = () => {
        if (isValid("firstname") && isValid("lastname") && isValid("initials")) {
            if (props.onClose) {
                props.onClose();
            }
            //setModal(!modal);
        } else { return false; }
    }
    useEffect(() => {
        //if (localStorage.getItem("firstname") === null) {
        //    setFirstname(localStorage.getItem("firstname"));
        //}
        //if (localStorage.getItem("lastname") === null) {
        //    setFirstname(localStorage.getItem("lastname"));
        //}
        //if (localStorage.getItem("initials") === null) {
        //    setFirstname(localStorage.getItem("initials"));
        //}
    }, []);

    //useEffect(() => console.log('value changed!'), [props.visible]);

    const isValid = (key) => {
        if (key === "firstname") {
            return firstname === null ? false : firstname.trim().length > 2;
        }
        if (key === "lastname") {
            return lastname === null ? false : lastname.trim().length > 2;
        }
        if (key === "initials") {
            return initials === null ? false : initials.trim().length > 1;
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "firstname") {
            setFirstname(e.target.value);
            localStorage.setItem("firstname", e.target.value);
            if (e.target.value.length > 0 && lastname.length > 0) {
                setInitials(e.target.value.substring(0, 1) + lastname.substring(0, 1));
                localStorage.setItem("initials", e.target.value.substring(0, 1) + lastname.substring(0, 1));
            }
        }
        if (e.target.name === "lastname") {
            setLastname(e.target.value);
            localStorage.setItem("lastname", e.target.value);
            if (e.target.value.length > 0 && firstname.length > 0) {
                setInitials(firstname.substring(0, 1) + e.target.value.substring(0, 1));
                localStorage.setItem("initials", firstname.substring(0, 1) + e.target.value.substring(0, 1));
            }
        }
        if (e.target.name === "initials") {
            localStorage.setItem("initials", e.target.value);
            setInitials(e.target.value);
        }
    }

    return (
        <Modal
            isOpen={props.visible}
            toggle={toggle}

            backdrop={"static"}
            keyboard={false}
        >
            <ModalHeader toggle={toggle} >Settings</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="firstname">Firstname</Label>
                    <Input type="text" name="firstname" value={firstname} onChange={handleChange} valid={isValid("firstname")} invalid={!isValid("firstname")} />
                </FormGroup>
                <FormGroup>
                    <Label for="lastname">Lastname</Label>
                    <Input type="text" name="lastname" value={lastname} onChange={handleChange} valid={isValid("lastname")} invalid={!isValid("lastname")} />
                </FormGroup>
                <FormGroup>
                    <Label for="">Initials</Label>
                    <Input type="text" name="initials" value={ initials} onChange={handleChange} valid={isValid("initials")} invalid={!isValid("initials")} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default SettingsModal;