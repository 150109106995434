import * as signalR from "@microsoft/signalr"
import appconfig from "./AppConfig";
//const URL = process.env.HUB_ADDRESS ?? "https://localhost:5001/hub" //or whatever your backend port is
const URL = process.env.HUB_ADDRESS ?? (appconfig.apiurl +"hub") //or whatever your backend port is

class Connector {
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, { withCredentials: false, skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
            .withAutomaticReconnect()
            .build()
        this.connection.start().catch(err => document.write(err))
        this.events = (onMessageReceived, onSomeOtherServerEventReceived) => {
            this.connection.on("messageReceived", (username, message) => {
                onMessageReceived(username, message)
            })
        }

    }
    newMessage = messages => {
        this.connection
            .send("newMessage", "foo", messages)
            .then(x => console.log("sent"))
    }
    static getInstance() {
        if (!Connector.instance) Connector.instance = new Connector()
        return Connector.instance
    }
}
export default Connector.getInstance