import appconfig from "../AppConfig";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, WMSTileLayer, BaseLayer, Marker, Popup, Tooltip, GeoJSON, LayersControl, useMap } from 'react-leaflet'
import L from "leaflet";
import React, { useState, useEffect } from "react";
//import VectorTileLayer from 'react-leaflet-vector-tile-layer';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { wgs84, osgb } from "./osgb";
import CompassControl from './CompassControl';


const svgIcon = L.divIcon({
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 448 512"
    width="25"
  height="30"
  preserveAspectRatio="none"
    >
    <path fill="#0000FF" d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
    </svg>
`,
    className: "",
    iconSize: [24, 40],
    iconAnchor: [12, 40],
});


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
function WildfireMap(props) {

    const [layerdata, setLayerdata] = useState([]);
    const [map, setMap] = useState(null);




    let wgs84obj = new wgs84();
    let osgbobj = new osgb();

    const gridref = (lon, lat) => {
        wgs84obj.setDegrees(lat, lon);
        let osgbref = wgs84obj.getOSGB();
        return osgbref.getGridRef(6);
    }

    async function getLayerData() {
        const responses = await Promise.all(
            //appconfig.maplayers.map(o => fetch(o.url, { referrerPolicy: "unsafe-url" }))
            appconfig.maplayers.map(o => fetch(o.url).then(resp => resp.json()))
        );
        let newdata = [];
        responses.forEach((response, index) => {
            newdata.push({
                name: Object.values(appconfig.maplayers)[index].name,
                json: response,
                visible: false,
                style: Object.values(appconfig.maplayers)[index].style
            });
        });
        setLayerdata(newdata);
    }

    useEffect(() => {
        getLayerData();
    }, []);



    const pallete = //["#099f56", "#0694c0", "#0c928f", "#0e8ac4", "#035398", "#02f6bb", "#099231", "#048306", "#0ac480", "#013555", "#07daef", "#038065", "#09f9f1", "#048513", "#04bdb8", "#0267e7", "#0bd9fd", "#082f7b", "#0d2588", "#059a85", "#08dad4", "#024d93", "#05e4f1", "#07a0c5", "#091027", "#0e2795", "#0a3276", "#0726c4", "#049165", "#0812b1", "#01d66b", "#0eb3e9", "#023e2a", "#073064", "#081842", "#099a0a", "#0b9892", "#0513d0", "#0e72ee", "#08a090", "#083519", "#0a2bf2", "#05b0e4", "#0a3fb1", "#01024e", "#00dfec", "#09efdf", "#00f2b8", "#01d080", "#01d65f", "#01fe71", "#08f9f3", "#059aba", "#00085e", "#098874", "#0f3e4c", "#0e846c", "#0a8119", "#0cf509", "#06849f", "#07252d", "#016e02", "#0a3b02", "#02cad6", "#02eb0f", "#00dca4", "#0f3bef", "#049eb2", "#0a6393", "#057beb", "#0150e7", "#0bc08c", "#0af454", "#07db6d", "#09ebd0", "#0bde61", "#08d656", "#02aac9", "#071f93", "#0a4b79", "#0d0f69", "#0850e7", "#0d66b0", "#0b71d1", "#09e8d7", "#0ab7b2", "#0ac8dd", "#05a728", "#0ec692", "#0d8b1a", "#0b1ea7", "#0eabe8", "#07629a", "#0420c3", "#0ac7ee", "#0f2be5", "#0e6997", "#072a57", "#076663"];
        [
            //
            "#FF0000", "#FF1493", "#FF4500", "#8A2BE2", "#OOOOFF", "#A52A2A", "#00BFFF", "#40E0D0", "#8B0000", "#E9967A", "#FFA500", "#FFD700", "#663399", "#191970", "#800000", "#696969",
            //
            "#FF0000", "#FF1493", "#FF4500", "#8A2BE2", "#OOOOFF", "#A52A2A", "#00BFFF", "#40E0D0", "#8B0000", "#E9967A", "#FFA500", "#FFD700", "#663399", "#191970", "#800000", "#696969",
            //
            "#FF0000", "#FF1493", "#FF4500", "#8A2BE2", "#OOOOFF", "#A52A2A", "#00BFFF", "#40E0D0", "#8B0000", "#E9967A", "#FFA500", "#FFD700", "#663399", "#191970", "#800000", "#696969",
        ];

    //let fireloadtypes = [];
    let featuretypearrays = {};
    const getFeatureStyle = (featuretypename, featuretype) => {
        //console.log(featuretypearrays[featuretypename]);
        if (featuretypearrays[featuretypename] === undefined) {
            featuretypearrays[featuretypename] = [];
        }
        if (featuretypearrays[featuretypename].findIndex(o => o === featuretype) === -1) {
            featuretypearrays[featuretypename].push(featuretype);
        }
        //console.log(loadtype, fireloadtypes.findIndex(o => o === loadtype), pallete[fireloadtypes.findIndex(o => o === loadtype)])
        let colour = pallete[featuretypearrays[featuretypename].findIndex(o => o === featuretype)];
        return {
            fillColor: colour,
            color: colour
        };
    }
    // position: relative; left: 0px; top: 0px; width: 100%; height: calc(100vh - 73px); margin: 0px;
    return (
        <div style={{ position: "relative", left: "0", top: "0px", width: "100%", height: 'calc(95vh - 73px)', margin: "0" }}>
            <MapContainer
                style={{ height: "100%", width: "100%" }}
                center={appconfig.center}
                zoom={11}

                minZoom={10}
                scrollWheelZoom={true}
                whenCreated={map => setMap(map)}
            >
                {/*<TileLayer*/}
                {/*    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'*/}
                {/*    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
                {/*/>*/}
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Ordanance Survey">
                        <TileLayer
                            attribution='&copy; Crown copyright 2024 and database rights OS AC0000821863'
                            url={"https://api.os.uk/maps/raster/v1/zxy/Outdoor_3857/{z}/{x}/{y}.png?key=" + appconfig.osapikey}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Google satalite">
                        <TileLayer
                            attribution='&copy; <a href="https://google.com/maps">Copyright Google</a>'
                            url={"http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"}
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <WMSTileLayer
                    layers={"inspire:brecon_beacons_national_park_boundary"}
                    url="https://gis.beacons-npa.gov.uk/geoserver/ows"
                    transparent={"true"}
                    format={"image/png"}
                />
                <LayersControl position="topright">
                    {layerdata.map((o, i) => (
                        <LayersControl.Overlay key={"overlay" + i} checked={o.checked} name={o.name}>
                            <GeoJSON
                                key={"layer" + i}
                                data={o.json}
                                onEachFeature={(feature, leafletLayer) => {
                                    const popupOptions = {
                                        minWidth: 100,
                                        maxWidth: 250,
                                        className: "popup-classname"
                                    };

                                    leafletLayer.bindPopup(() => {
                                        let tooltip = "";
                                        tooltip = tooltip + '<b>' + feature.id.substring(0, feature.id.indexOf('.')).replace("_", " ") + '</b>'
                                        if (feature.properties) {
                                            for (var key of Object.keys(feature.properties)) {
                                                if (feature.properties[key] !== null) {
                                                    tooltip += "</br>" + key + ": " + feature.properties[key];
                                                }
                                            }
                                            //console.log(feature);
                                            //console.log(feature.properties);
                                        }
                                        /*for (var info of feature.properties) {
                                            console.log(info);
                                        }*/

                                        return tooltip;
                                    }, popupOptions);
                                    //feature.style = { color: 'yellow', fillColor: 'yellow' };
                                    //console.log(feature);
                                }}

                                style={(feature) => {
                                    if (feature.id.startsWith("fire_load") ) {
                                        return getFeatureStyle("fire_load", feature.properties.cls_3);
                                        //console.log(feature.properties.cls_3);
                                    }
                                    else if (feature.id.startsWith("landownership")) {
                                        return getFeatureStyle("landownership", feature.properties.owner);
                                    }
                                    else if (feature.id.startsWith("wardens_areas")) {
                                        return getFeatureStyle("wardens_areas", feature.properties.firstname + feature.properties.surname);
                                    }
                                    else if (feature.id.startsWith("protected_areas")) {
                                        return getFeatureStyle("protected_areas", feature.properties.name1);
                                    }
                                    else {
                                        if (!feature.id.startsWith("roads") && !feature.id.startsWith("water_source") && !feature.id.startsWith("deep_peat") && !feature.id.startsWith("off_road_access_routs")) {
                                            //console.log(feature.id, feature.properties);
                                        }
                                        return o.style;
                                    }
                                }}
                            />
                        </LayersControl.Overlay>
                    ))}
                    <LayersControl.Overlay key={"overlay-1"} checked={true} name="People">
                        {props.peoplepositions ? props.peoplepositions.map((o, i) => (
                            <Marker /*icon={svgIcon}*/ key={"person" + o} position={[o.lat, o.lon]}>
                                <Tooltip direction="right" offset={[20, 13]} opacity={1} permanent>{o.initials.toUpperCase()}</Tooltip>
                                <Popup>
                                    {o.firstname + " " + o.lastname}<br />
                                    {gridref(o.lon, o.lat)}<br />
                                    Lng:{o.lon},Lat:{o.lat}
                                </Popup>
                            </Marker>
                        )) : (<></>)}
                    </LayersControl.Overlay>
                </LayersControl>
                <CompassControl map={map} position={props.position} />
                {/*{layerdata.length > 0 ? (*/}
                {/*    <GeoJSON key="layertest" data={layerdata[0].json} />*/}
                {/*) : (<></>)*/}
                }

            </MapContainer>
        </div>
    );

}

export default WildfireMap;