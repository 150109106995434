import React, { useState, useEffect } from "react";
import compassneedle from './Compass.png';
//import { useGeolocated } from "react-geolocated";
import { wgs84, osgb } from "./osgb";
//leaflet-control leaflet-bar map-legend
function MapLegendControl({ map,position }) {
    
    const [direction, setDirection] = useState(0);

    //const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    //    useGeolocated({
    //        positionOptions: {
    //            //enableHighAccuracy: true,
    //            enableHighAccuracy: false,
    //            maximumAge: 10,
    //        },
    //        userDecisionTimeout: 5000,
    //        //onSuccess: updateLocation(coords),
    //        watchPosition: true,
    //    });
    

    useEffect(() => {
        const isIOS = (
            navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
            navigator.userAgent.match(/AppleWebKit/)
        );
        if (isIOS) {
            DeviceOrientationEvent.requestPermission()
                .then((response) => {
                    if (response === "granted") {
                        window.addEventListener("deviceorientation", handler, true);
                    } else {
                        alert("has to be allowed!");
                    }
                })
                .catch(() => alert("not supported"));
        } else {
            window.addEventListener("deviceorientationabsolute", handler, true);
        }

    }, []);

    const handler = (e) => {
        let compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
        setDirection(-compass);

    }

    let wgs84obj = new wgs84();
    //let osgbobj = new osgb();

    const getGridref = (lon, lat) => {
        wgs84obj.setDegrees(lat, lon);
        let osgbref = wgs84obj.getOSGB();
        return osgbref.getGridRef(6);
    }
    
    return (
        <div className='leaflet-control leaflet-bottom leaflet-left'>
            <img width="120px" height="120px" src={compassneedle} style={{ transform: "rotate(" + direction + "deg)" }} alt="compass needle" />

            <p style={{ backgroundColor:"#fff" }}>{position ? "Lng: " + position.lng + " ,Lat:" + position.lat : ""}<br />
                {position ? "OS Ref: " + getGridref(position.lng, position.lat) : ""} </p>
        </div>
    )
}

export default MapLegendControl;